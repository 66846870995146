.container-fluid
  position: relative
  background-color: #E5E5E5
  height: 100vh
  display: flex
  color: #554D66
  font-family: "IBM regular"
  main
    flex: 8
    position: relative

::-webkit-scrollbar
  width: 5px
  background-color: #cdcbdc
  border-radius: 5px

::-webkit-scrollbar-thumb
  background-color: #49446C
  border-radius: 9em

::-webkit-scrollbar-thumb:hover
  background-color: #9c8ede

ul
  list-style-type: none
  padding-left: 0px
