.modal-edit-button
  color: #1485ED
  background-color: #F8F9FA
  border: 2px solid #1485ED
  border-radius: 6px
  width: 140px
  padding: 0.001rem 0.0001rem
.modal-add-button
  color: green
  background-color: #F8F9FA
  border: 2px solid green
  border-radius: 6px
  width: 140px
  padding: 0.001rem 0.0001rem

.ul-user-item-normal
  list-style-type: none
  display: flex
  padding-left: 0px
  padding-top: 5px
  padding-bottom: 5px
  margin: 0
  li:nth-child(1)
    width: 14.5%
    padding-left: 25px
    margin-left: 16px
    border-bottom-left-radius: 3px
    border-top-left-radius: 3px
  li:nth-child(2)
    width: 13%
  li:nth-child(3)
    width: 11%
  li:nth-child(4)
    width: 7.2%
  li:nth-child(5)
    width: 5.5%
  li:last-child
    width: 42%
    display: flex
    justify-content: end
    opacity: 0
  li:last-child:hover
    opacity: 1
  li.first-ul
    padding-left: 4px
    display: flex
    align-items: center
.red-background
  background-color: rgba(249, 224, 225, 0.5)
.ul-user-item-normal:hover
  background-color: #F2F4F5
.ul-user-item-normal:hover > li:nth-child(7)
  opacity: 1
  background-color: #F2F4F5

.ul-user-item-normal-role
  list-style-type: none
  display: flex
  padding-left: 0px
  padding-top: 5px
  padding-bottom: 5px
  margin: 0
  li:nth-child(1)
    width: 14.5%
    padding-left: 25px
    margin-left: 16px
    border-bottom-left-radius: 3px
    border-top-left-radius: 3px
  li:nth-child(2)
    width: 13%
  li:nth-child(3)
    width: 11%
  li:nth-child(4)
    width: 7%
  li:nth-child(5)
    width: 5%
  li:nth-child(6)
    width: 11.5%
    border-bottom-right-radius: 3px
    border-top-right-radius: 3px
  li:nth-child(7)
    width: 5.8%
    span
      color: #ffffff
      background-color: #8E88BE
      border-radius: 20px
      padding: 3px 10px
  li:nth-child(8)
    width: 16.5%
    border-bottom-right-radius: 3px
    border-top-right-radius: 3px
  li:last-child
    display: flex
    justify-content: end
    opacity: 0
  li:last-child:hover
    opacity: 1
  li.first-ul
    padding-left: 4px
    display: flex
    align-items: center
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
.red-background
  background-color: rgba(249, 224, 225, 0.5)
.ul-user-item-normal-role:hover
  background-color: #F2F4F5
.ul-user-item-normal-role:hover > li:last-child
  opacity: 1
  background-color: #F2F4F5
