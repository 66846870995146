@font-face {
    font-family: "IBM bold";
    src: url(./IBMPlexSans-Bold.ttf);
}
@font-face {
    font-family: "IBM regular";
    src: url(./IBMPlexSans-Regular.ttf);
}
@font-face {
    font-family: "IBM medium";
    src: url(./IBMPlexSans-Medium.ttf);
}
