.table-container
  width: 87%
  margin: 0 auto
  overflow-y: auto
  max-height: calc(100vh - 205px)
  &::-webkit-scrollbar
    width: 0px

.report-ent-name
  color: white
  padding: 7px 12px
  margin-left: 10px
  background-color: #9993C5
.table-up, .table-down, .table-total
  display: grid
  width: 100%
  grid-auto-rows: minmax(40px, auto)
  padding-inline-start: 10px
  margin-bottom: 0
  li
    border: 1px solid #E0E0E0
    background-color: white
    padding: 5px 10px
    list-style-type: none
.table-up, .table-down
  grid-template-columns: 15% 10% 1fr 15% 15% 15%
.table-total
  grid-template-columns: 1fr 15% 15% 15%
  margin-top: 25px
  margin-bottom: 25px
  li:first-child
    grid-row: 1/3
    display: flex
    align-items: center
  li:first-child, li:nth-child(2), li:nth-child(3), li:nth-child(4)
    font-weight: bold
  li:nth-child(5), li:nth-child(6), li:nth-child(7)
    text-align: end
.table-down
  margin-bottom: 25px
  li:first-child
    grid-column: 1/4
    grid-row: 1/3
    display: flex
    align-items: center
  li:nth-child(2), li:nth-child(3)
    font-weight: bold
  li:nth-child(5), li:nth-child(6)
    text-align: end
.start-report-date, .end-report-date
  margin-right: 30px
  border: 1px solid #E0E0E0
  padding: 7px 15px
  border-radius: 5px
  margin-bottom: 40px
.start-report-date
  margin-left: 30px
.btn-report
  border: 1px solid #E0E0E0
  padding: 7px 15px
  background-color: #9993C5
  border-radius: 5px
  color: white
.report-data
  padding-bottom: 30px

