.switch
  width: 40px
  height: 20px
  border-radius: 42px
  background-color: #42CB6F
  padding: 1px 1px 1px 21px
  .switch-button
    width: 18px
    height: 18px
    background-color: #fff
    border-radius: 50%

.switch-active
  width: 40px
  height: 20px
  border-radius: 42px
  background-color: #A5ADBA
  padding: 1px 21px 1px 1px
  .switch-button
    width: 18px
    height: 18px
    background-color: #fff
    border-radius: 50%




