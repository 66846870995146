.modal-meeting
  height: calc(100vh - 150px)
  background-color: rgba(0, 0, 0, 0.0001)
  position: fixed
  top: 0
  left: 0
  display: flex
  justify-content: center
  align-items: center
  z-index: 200
  opacity: 0
  pointer-events: none
  transition: .5s
  .modal-content-meeting
    position: relative
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
    background-color: #F8F9FA
    border: 1px solid #C6C2CF
    border-radius: 8px
    width: 600px
    height: 475px
    padding: 16px
    .modal-content-meeting-div
      padding-right: 27px
      .div-img-clock-time
        .div-img
          margin-right: 10px
      .div-img-account-search-kids
        .div-img
          height: 25px
      .div-img-accounts-search-user
        .div-img
          margin-top: 5px
          width: 21px


      .div-button-send
        width: 100%
        display: flex
        justify-content: flex-end
        align-items: flex-end
        button
          position: absolute
          border: none
          width: 164px
          border-radius: 6px
          padding: 8px
          background-color: #1485ED
          color: #ffffff
          font-size: 16px
          bottom: 20px
          right: 20px
          &:disabled
            background-color: #DBE2EA

.search-user
  position: relative
  border: 1px solid #DBE2EA
  width: 272px
  padding: 8px 15px
  border-radius: 6px
  background-image: url("../img/plus.png")
  background-repeat: no-repeat
  background-position: 245px 10px
.search-user::placeholder
  color: #C6C2CF
  font-size: 12px
.search-user:focus
  outline: none

.entertainer-input
  margin: 7px 0px 7px 30px

.theme-input
  background-image: url("../img/Vector (Stroke).png")
  background-position: 245px 15px

.search-user-children
  //margin: 5px 0px
  width: 210px
  background-image: url("../img/Vector (Stroke).png")
  background-position: 185px 15px
.character
  margin: 7px 0px 7px 0px
  width: 210px
  padding: 6px 15px 9px 15px
  background-image: url("../img/Vector (Stroke).png")
  background-position: 185px 15px
.search-user-children::placeholder
  color: gray

.character::placeholder
  color: #C6C2CF


.header-kid
  width: 230px
  background-position: 200px 13px

.modal-meeting.active
  opacity: 1
  pointer-events: all
  position: relative

.create-button
  border: none
  border-radius: 5px
  display: block
  color: #ffffff
  background-color: #1485ED
  font-size: 25px
  width: 140px
  float: right

.create-button.show-create-button
  display: none

.div-user-send
  display: flex
  margin-left: 30px
  margin-top: 8px
  align-items: center
  background-color: #ffffff
  border: 1px solid #DBE2EA
  height: 40px
  border-radius: 5px
  padding: 0px 5px 0px 10px
  li
    margin-right: 5px
  li:last-child
    color: gray
    font-size: 12px
    display: flex
    margin-top: 4px
    div
      img
        padding-bottom: 5px
        padding-left: 10px

.div-kid-send
  display: flex
  margin-left: 30px
  margin-top: 6px
  align-items: center
  font-weight: 400
  gap: 10px
  border-radius: 5px
  border: 1px solid #DBE2EA
  background-color: #ffffff
  height: 42px
  padding: 0px 10px
  font-size: 14px
  li:last-child
    font-size: 14px
    font-weight: 600
    color: #554D66
    div
      img
        padding-bottom: 10px
        padding-left: 5px
