.row-main-panel
  width: 230px
  height: 100vh
  padding: 20px 10px 15px 10px

  .navbar-col
    background: linear-gradient(#9993C5, #7C75B1)
    color: #fff
    border-radius: 12px
    padding: 10px
    height: 100%
    position: relative
    .new-date
      text-align: center
      padding-top: 5px
      font-size: 14px
    .navbar-hr
      color: #fff
    .navbar-div-user-name
      display: flex
      justify-content: center
      align-items: center
    .navbar-div-user-name
      a
        display: block
        text-decoration: none
        color: #fff
        .navbar-div-user-img-arr
          margin-left: 10px
          transform: rotate(90deg)
          width: 6px
    .navbar-p-main
      text-transform: uppercase
      font-size: 14px
      padding-left: 15px
    .navbar-ul
      list-style-type: none
      margin-left: 0
      padding-left: 0
      .navbar-ul-li:first-child
        background-image: url("./img/dashboards.png")
      .navbar-ul-li:nth-child(2)
        background-image: url("./img/accounts.png")
      .navbar-ul-li:nth-child(3)
        background-image: url("./img/report.png")
      .navbar-ul-li:nth-child(6)
        background-image: url("./img/XMLID_1_.png")
      .navbar-ul-li:nth-child(7)
        background-image: url("./img/Frame.png")
      .navbar-ul-li:last-child
        background-image: url("./img/logOutWhite.png")
        background-size: 20px
        background-position: 15px 18px
      .navbar-ul-li
        margin-top: 6px
        background-repeat: no-repeat
        background-position: 15px 20px
        background-image: url("./img/dashboards.png")
      .navbar-ul-li
        a
          display: block
          padding: 15px 15px 15px 50px
          text-decoration: none
          color: #ffffff
          background-image: url("./img/arrowsymbol.png")
          background-repeat: no-repeat
          background-position: 160px 20px
          background-size: 6px
      .navbar-ul-li:hover
        background-color: #736CA5
        border-radius: 4px
      .nav-active
        color: #49446C
    .navbar-p-copy
      font-size: 12px
      position: absolute
      padding: 0 5px
      bottom: 0

.heart
  width: 10px










