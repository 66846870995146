.users-collection-row
  border-radius: 8px
  height: calc(100vh - 87px)
  margin-left: 20px
  margin-right: 10px
  //overflow-y: auto

  background: linear-gradient(to right, #F8F9FA 16px, #F2F4F5 16px, #F2F4F5 15%, #F8F9FA 15%, #F8F9FA 28%, #F2F4F5 28%, #F2F4F5 39%, #F8F9FA 39%, #F8F9FA 46%, #F2F4F5 46%, #F2F4F5 51%, #F8F9FA 51%)
  padding-top: 22px
  align-content: flex-start
  .users-collection-col-names-of-user-list
    font-family: "IBM medium"
    font-size: 13px
    color: #554D66
  .users-collection-col-users-of-user-list
    font-family: "IBM regular"
    font-size: 13px
    margin-top: 20px
    overflow-y: auto
    height: calc(100vh - 200px)
.users-collection-row-role
  border-radius: 8px
  height: calc(100vh - 87px)
  margin-left: 20px
  margin-right: 10px
  //overflow-y: auto

  background: linear-gradient(to right, #F8F9FA 16px, #F2F4F5 16px, #F2F4F5 15%, #F8F9FA 15%, #F8F9FA 28%, #F2F4F5 28%, #F2F4F5 39%, #F8F9FA 39%, #F8F9FA 46%, #F2F4F5 46%, #F2F4F5 51%, #F8F9FA 51%, #F8F9FA 62%, #F2F4F5 62%, #F2F4F5 68%, #F8F9FA 68%)
  padding-top: 22px
  align-content: flex-start
  .users-collection-col-names-of-user-list
    font-family: "IBM medium"
    font-size: 13px
    color: #554D66
  .users-collection-col-users-of-user-list
    font-family: "IBM regular"
    font-size: 13px
    margin-top: 20px
    overflow-y: auto
    height: calc(100vh - 200px)
