.row-not-found
  background-image: url("./carrot.jpg")
  height: 100vh
  background-repeat: no-repeat
  background-size: cover
  display: flex
  justify-content: center
  align-items: center
  align-content: flex-end
  .h2-not-found
    font-size: 70px
    font-family: "IBM bold"
    color: rgb(189, 202, 219)
    line-height: 1.472
    text-align: center
    -webkit-text-stroke-width: 5px
    -webkit-text-stroke-color: rgba(#000, 0.3)
    width: auto
  div
    width: 100px
    margin-left: 20px
