.row-user-header
  position: relative
  padding: 0px 0px 0px 20px
  .users-header-col-h2
    h2
      color: #554D66
      font-size: 32px
      font-family: "IBM bold"
      margin-top: 20px
  .col-user-header-nav
    padding: 10px 20px
    .me-auto
      box-sizing: border-box
      padding-top: 7px
      width: 386px
      height: 40px
      border-radius: 11px
      background-color: #fff
      display: flex
      align-items: center
      justify-content: space-evenly
      .user-header-radio
        position: absolute
        z-index: -1
        opacity: 0
        & + label
          cursor: pointer
          border-radius: 8px
          box-shadow: -1px 3px 10px -4px rgba(0, 0, 0, 0.85)
          padding: 2px 20px
          display: inline-block
          text-decoration: none
          color: #554D66
          font-size: 14px

        &:checked + label
          background-color: #1485ED
          box-shadow: none
          color: #fff

