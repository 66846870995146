.input-search
  position: absolute
  top: -60px
  right: 30px
  color: #7C9CBF
  input
    padding: 6px 75px 6px 10px
    font-size: 16px
    border: 1px solid #DBE2EA
    border-radius: 6px
    background-color: #E5E5E5
    position: relative
  input:focus
    outline: none
  .button-img-search
    position: absolute
    top: 3px
    right: 5px
    border: none
    background-color: #E5E5E5
