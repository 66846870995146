.row
  --bs-gutter-x: 0

.row-auth
  background-color: #F0F2F5
  background-image: url("./img/right-top-bg.png")
  height: 100vh
  background-repeat: no-repeat
  background-position: right top
  align-content: start
  padding-top: 147px
  font-family: "IBM regular"
  .div-img
    width: 252px
    height: 145px
    margin: auto
    img
      width: 100%
  .form
    display: flex
    flex-direction: column
    align-items: center
    .codeInput:focus
      outline: none
    h3.h3-auth
      margin-top: 75px
      margin-bottom: 10px
      font-size: 16px
      width: 272px
      padding-left: 5px
      color: #756F86
    h3.h3-code
      margin-left: 65px
    .input-code-number
      width: 270px
      margin-bottom: 5px
    .p-sms-info
      color: #756F86
      font-size: 14px
      font-weight: 300
      margin-bottom: 0
    .resending-sms-timer
      color: #FF7171
      font-size: 13px
      font-weight: 400
      cursor: pointer
    .resend
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      .resending-sms
        margin-top: 10px
        margin-bottom: 0
        color: #14A38B
        font-size: 14px
        font-weight: 400
        cursor: pointer
    .code-input-div
      display: flex
      justify-content: space-between
      align-items: center
      width: 200px
      margin-top: 20px
      .code-input-button
        width: 93px
      .code-input-button:first-child
        background-color: #1485ED
        border-color: #1485ED
      .code-input-button:last-child
        background-color: #14A38B
        border-color: #14A38B
      .code-input-button:disabled
        color: #2C2738
        background-color: #DBE2EA
        border-color: #DBE2EA
    .input-phone-number
      width: 272px
      font-size: 14px
      color: #7C9CBF !important
      margin-bottom: 109px
    .btn-auth
      width: 272px
      font-size: 16px
  .col-p
    display: flex
    justify-content: center
    position: absolute
    bottom: 0
    border-radius: 6px
    .p-auth
      color: #C6C2CF
      font-size: 12px

.heart
  width: 10px




