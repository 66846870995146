.alert
  position: absolute
  background-color: #f4f4f4
  top: 20px
  z-index: 5000

.alert-success
  padding: 20px
  box-shadow: 0 0 20px lightgrey

.alert-heading.h4
  color: red
  border-bottom: 1px solid red
  padding-bottom: 15px
  margin-bottom: 30px

.btn-outline-success
  width: 100%
  background-color: #14A38B
  color: #fff


